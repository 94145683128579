import { render, staticRenderFns } from "./event.vue?vue&type=template&id=1c9922b3&scoped=true&"
import script from "./event.vue?vue&type=script&lang=js&"
export * from "./event.vue?vue&type=script&lang=js&"
import style0 from "./event.vue?vue&type=style&index=0&lang=css&"
import style1 from "./event.vue?vue&type=style&index=1&id=1c9922b3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c9922b3",
  null
  
)

component.options.__file = "event.vue"
export default component.exports