import { render, staticRenderFns } from "./add.vue?vue&type=template&id=96a481ce&scoped=true&"
import script from "./add.vue?vue&type=script&lang=js&"
export * from "./add.vue?vue&type=script&lang=js&"
import style0 from "./add.vue?vue&type=style&index=0&id=96a481ce&lang=scss&scoped=true&"
import style1 from "./add.vue?vue&type=style&index=1&lang=css&"
import style2 from "./add.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96a481ce",
  null
  
)

component.options.__file = "add.vue"
export default component.exports